import {SectionContainer, SectionPreTitle, SectionTitle} from "../../components/Shared"
import {Layout} from "../../components/Layout"
import React from "react"
import {Card, Grid,} from "@material-ui/core"
import {useI18next} from "gatsby-plugin-react-i18next"
import {GameCard} from "../../components/GameCard"
import styled from "@emotion/styled"
import {graphql, useStaticQuery} from "gatsby"
import {Query} from "../../../graphql-types"


const PaddedCard = styled(Card)(({theme}) => ({
  padding: theme.spacings.sm,
  marginTop: theme.spacings.md,
  marginBottom: theme.spacings.md
}))
const Marged = styled.span(({theme}) => ({
  marginRight: theme.spacings.md,
}))

export const LiveGamesPage = () => {

  const allLiveGames = useStaticQuery<Query>(graphql`
    query AllLiveGameQuery {
      allLiveGame {
        edges {
          node {
            joueurs {
              text
            }
            systeme {
              text
            }
            noms {
              region
              text
            }
            synopsis {
              langue
              text
            }
            note {
              text
            }
            dates {
              region
              text
            }
            developpeur {
              text
            }
            genres {
              noms {
                langue
                text
              }
            }
            videoLocal {
              publicURL
            }
            wheelLocal {
              childImageSharp {
                gatsbyImageData
              }
            }
            ssLocal {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `).allLiveGame.edges.map(_ => _.node)
  const i18n = useI18next()
  return (
    <Layout title={"Recalbox Live Game Database"}>
      <SectionContainer>
        <SectionPreTitle>What are you searching ?</SectionPreTitle>
        <SectionTitle>Tous les jeux de course du live 18 Janvier 2023 !</SectionTitle>

        <Grid container spacing={4}>
          {allLiveGames.map((game: any) => {
            return (
              <Grid item xs={12} sm={4} lg={3}>
                <GameCard key={game.id} game={game} currentLang={i18n.language}></GameCard>
              </Grid>
            )
          })}
        </Grid>
      </SectionContainer>
    </Layout>
  )
}
